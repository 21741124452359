<template>
  <div class="contianer_profile">
    <v-row v-if="profile">
      <v-col cols="3">
        <p class="ma-0 smaller_txt">Fasyankes</p>
        <b class="small_txt">{{ profile.name }}</b>
      </v-col>
      <v-col cols="3">
        <p class="ma-0 smaller_txt">Provinsi</p>
        <div class="d-flex align-center">
          <b class="small_txt">{{ profile.provinsi_name }}</b
          >,
          <p class="small_txt ma-0">Kode: </p>
          <b class="small_txt">{{ profile.provinsi_id }}</b>
        </div>
      </v-col>
      <v-col cols="3">
        <p class="ma-0 smaller_txt">Kabupaten/Kota</p>
        <div class="d-flex align-center">
          <b class="small_txt">{{ profile.kabupaten_name }}</b
          >,
          <p class="small_txt ma-0">Kode: </p>
          <b class="small_txt">{{ profile.kabupatenkota_id }}</b>
        </div>
      </v-col>
      <v-col cols="3">
        <p class="ma-0 smaller_txt">Kecamatan</p>
        <div class="d-flex align-center">
          <b class="small_txt">{{ profile.kecamatan_name }}</b
          >,
          <p class="small_txt ma-0">Kode: </p>
          <b class="small_txt">{{ profile.kecamatan_id }}</b>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "profileDetilFasyankes",
  props: ["profile"],
};
</script>

<style scoped>
.contianer_profile {
  padding: 15px;
  background: #fafafa;
}
.smaller_txt {
  font-size: smaller;
}
</style>